import { useEffect, useState, useRef } from "react";

function Heronet() {
  const typingAnimationRef = useRef(null);
  const [textIndex, setTextIndex] = useState(0);
  const typingTexts = ["Founders", "Creators", "Marketers "];
  const typingSpeed = 100; // Speed of typing in ms
  const deletingSpeed = 50; // Speed of deleting in ms
  const delayBetweenTexts = 1000; // Delay between texts in ms

  useEffect(() => {
    const typingElement = typingAnimationRef.current;
    let typingTimeout, deletingTimeout;

    const typeText = (text, callback) => {
      let i = 0;
      typingElement.textContent = "";
      typingTimeout = setInterval(() => {
        typingElement.textContent += text[i];
        i++;
        if (i >= text.length) {
          clearInterval(typingTimeout);
          setTimeout(() => {
            deleteText(callback);
          }, delayBetweenTexts); // Delay before deleting
        }
      }, typingSpeed);
    };

    const deleteText = (callback) => {
      let i = typingElement.textContent.length;
      deletingTimeout = setInterval(() => {
        typingElement.textContent = typingElement.textContent.slice(0, -1);
        i--;
        if (i < 0) {
          clearInterval(deletingTimeout);
          callback();
        }
      }, deletingSpeed);
    };

    const startTypingAnimation = (index) => {
      typeText(typingTexts[index], () => {
        startTypingAnimation((index + 1) % typingTexts.length);
      });
    };

    startTypingAnimation(textIndex);

    return () => {
      clearInterval(typingTimeout);
      clearInterval(deletingTimeout);
    };
  }, [textIndex]);

  return (
    <div
      className="fugu--hero-section"
      // style={{
      //   backgroundImage: `url('https://cdn.prod.website-files.com/650f276b38fe3eaebe9f805a/6683edf1120501852cfa539a_Telos%20Website%20Hero-p-2000.png')`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover",
      //   backgroundPosition: "50% 50%",
      //   backgroundColor: "#f9f9f9",
      // }}
    >
      <video muted autoPlay loop id="myVideo" className="video">
        <source
          src="assets/images/img-one/hero_section_video.mp4"
          type="video/mp4"
        />
      </video>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="fugu--hero-conten fugu--hero-content3">
              <h1
                className="wow fadeInUpX text-white text-center fa-1"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                Blockchain for Networking <br /> & Decentralized Communities
              </h1>
              <div className="">
                <p
                  className="wow fadeInUpX text-white text-center fs-3 "
                  data-wow-delay="0.15s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.15s",
                    animationName: "fadeInUpX",
                  }}
                >
                  Start developing decentralized applications (dApps) on
                  Network1 with benefiting from low-cost, fast & scalable
                  infrastructure
                </p>
                {/* typingAnimation */}
                <div className="div-block-143">
                  <div className="typingAnimation-box">
                    <h3 className="">Network1 for</h3>
                    <h3 className="" ref={typingAnimationRef}></h3>
                  </div>
                </div>
                <br />
                <div className="">
                  <div className="row ">
                    <div className="col-lg-6 mt-2 ">
                      <div className="button_section ">
                        <a
                          className="w-inline-block "
                          href="https://network1.gitbook.io/documentation"
                          target="_blank"
                        >
                          <div className="heading-style-h5">Documentation</div>
                          <div className="text-size-medium">
                            Get started with exploring Network1, technical
                            details & guides.
                          </div>
                          <span>
                            <svg
                              style={{
                                marginLeft: "90%",
                                marginTop: "10%",
                                color: "black",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="currentColor"
                              class="bi bi-arrow-right-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <div className="button_section">
                        <a
                          className=" w-inline-block"
                          href="https://network1.gitbook.io/network1-whitepaper"
                          target="_blank"
                        >
                          <div className="heading-style-h5">Whitepaper</div>
                          <div className="text-size-medium">
                            Read fundamental concepts & founding ideas about
                            Network1.
                          </div>
                          <span>
                            <svg
                              style={{
                                marginLeft: "90%",
                                marginTop: "10%",
                                color: "black",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="currentColor"
                              class="bi bi-arrow-right-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heronet;
